const colors = {
  boxColor: "#F3F3F3",
  borderColor: "#A5A6A1",
  fontHeadingColor: "#595959",
  fontColor: "#B2B2B2",
  backgroundColor: "#F2F2F2",
  themeColor: "#00163C",
};

export default colors;
